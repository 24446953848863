'use client';
import { USER_ROLE } from '@utils/auth/roles';
import { UserAgencyDetails } from '@v2/types';
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Typography,
} from '@v2/ui';
import { format } from 'date-fns';
import { useSession } from 'next-auth/react';
import { useState } from 'react';
import { AfilliateDetailsForm } from './AfilliateDetailsForm';

export const DEFAULT_PLACEHOLDER = 'Not provided';

export const AfilliateDetails = (props: UserAgencyDetails) => {
  const {
    createdAt,
    commissionRate,
    twitter,
    instagram,
    tiktok,
    businessName,
    industry,
    country,
    streetAddress,
    zipCode,
    website,
    city,
    youtube,
    additionalInfo,
  } = props;

  const { data } = useSession();

  // @ts-ignore
  const isSuperAdmin = data?.user.role === USER_ROLE.superAdmin;

  const [isEditingMode, setIsEditingMode] = useState(false);

  return (
    <div className="mb-20">
      <div className="mb-5 flex items-center justify-between">
        <h4 className="text-xl font-bold text-gray-800 md:text-2xl">Details</h4>
        {isSuperAdmin ? (
          <Button
            variant="link"
            type="button"
            onClick={() => setIsEditingMode((old) => !old)}
          >
            {isEditingMode ? 'Cancel' : 'Edit'}
          </Button>
        ) : (
          <div />
        )}
      </div>

      {isSuperAdmin && isEditingMode ? (
        <AfilliateDetailsForm
          {...props}
          onClose={() => {
            setIsEditingMode(false);
          }}
        />
      ) : (
        <div className="grid grid-cols-2 gap-x-6 gap-y-5 md:grid-cols-3">
          <div className="w-full">
            <Typography size="tsm" className="font-medium text-gray-500">
              Signed-up since
            </Typography>
            <Typography size="tmd" className="font-semibold text-gray-800">
              {createdAt
                ? format(new Date(createdAt), 'd MMM, yyyy')
                : DEFAULT_PLACEHOLDER}
            </Typography>
          </div>

          <div className="w-full">
            <Typography size="tsm" className="font-medium text-gray-500">
              Referral comission
            </Typography>
            <Typography size="tmd" className="font-semibold text-gray-800">
              {commissionRate}%
            </Typography>
          </div>

          <div className="w-full">
            <Typography size="tsm" className="font-medium text-gray-500">
              Business name
            </Typography>
            <Typography size="tmd" className="font-semibold text-gray-800">
              {businessName ?? DEFAULT_PLACEHOLDER}
            </Typography>
          </div>

          <div className="w-full">
            <Typography size="tsm" className="font-medium text-gray-500">
              industry
            </Typography>
            <Typography size="tmd" className="font-semibold text-gray-800">
              {industry ?? DEFAULT_PLACEHOLDER}
            </Typography>
          </div>
          <div className="w-full">
            <Typography size="tsm" className="font-medium text-gray-500">
              Country
            </Typography>
            <Typography size="tmd" className="font-semibold text-gray-800">
              {country ?? DEFAULT_PLACEHOLDER}
            </Typography>
          </div>
          <div className="w-full">
            <Typography size="tsm" className="font-medium text-gray-500">
              City
            </Typography>
            <Typography size="tmd" className="font-semibold text-gray-800">
              {city ?? DEFAULT_PLACEHOLDER}
            </Typography>
          </div>
          <div className="w-full">
            <Typography size="tsm" className="font-medium text-gray-500">
              Address
            </Typography>
            <Typography size="tmd" className="font-semibold text-gray-800">
              {streetAddress ?? DEFAULT_PLACEHOLDER}
            </Typography>
          </div>

          <div className="w-full">
            <Typography size="tsm" className="font-medium text-gray-500">
              ZIP/postal code
            </Typography>
            <Typography size="tmd" className="font-semibold text-gray-800">
              {zipCode ?? DEFAULT_PLACEHOLDER}
            </Typography>
          </div>

          <div className="w-full">
            <Typography size="tsm" className="font-medium text-gray-500">
              Website
            </Typography>
            <Typography size="tmd" className="font-semibold text-gray-800">
              {website ?? DEFAULT_PLACEHOLDER}
            </Typography>
          </div>

          <div className="w-full">
            <Typography size="tsm" className="font-medium text-gray-500">
              Additional info
            </Typography>
            <Typography size="tmd" className="font-semibold text-gray-800">
              {additionalInfo ?? DEFAULT_PLACEHOLDER}
            </Typography>
          </div>
          <div className="w-full">
            <Typography size="tsm" className="font-medium text-gray-500">
              Instagram
            </Typography>
            <ColumnSocialMedia value={instagram} />
          </div>
          <div className="w-full">
            <Typography size="tsm" className="font-medium text-gray-500">
              Twitter
            </Typography>
            <ColumnSocialMedia value={twitter} />
          </div>
          <div className="w-full">
            <Typography size="tsm" className="font-medium text-gray-500">
              Tiktok
            </Typography>

            <ColumnSocialMedia value={tiktok} />
          </div>
          <div className="w-full">
            <Typography size="tsm" className="font-medium text-gray-500">
              Youtube
            </Typography>

            <ColumnSocialMedia value={youtube} />
          </div>
        </div>
      )}
    </div>
  );
};

export function ColumnSocialMedia({ value }: { value: string | undefined }) {
  if (!value) {
    return (
      <Typography
        size="tmd"
        className="max-w-[15ch] truncate font-semibold text-gray-800 md:max-w-[20ch]"
      >
        {DEFAULT_PLACEHOLDER}
      </Typography>
    );
  }
  return (
    <Tooltip>
      <TooltipTrigger>
        <Typography
          size="tmd"
          className="max-w-[15ch] truncate font-semibold text-gray-800 md:max-w-[20ch]"
        >
          <a href={value} target="_blank">
            {value}
          </a>
        </Typography>
      </TooltipTrigger>

      <TooltipContent>
        <Typography size="tmd" className="font-semibold text-gray-800">
          <a href={value} target="_blank">
            {value}
          </a>
        </Typography>
      </TooltipContent>
    </Tooltip>
  );
}
